import React from "react";
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Button from "react-md/lib/Buttons/Button";
import Card from "react-md/lib/Cards/Card";
import Media from "react-md/lib/Media/Media";
import MediaOverlay from "react-md/lib/Media/MediaOverlay";

import "./MainPanel.scss";

const MainPanel = () => (

  <StaticQuery
    query={graphql`
      query {
        Image1: file(relativePath: { eq: "11.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Card raised="true">
        <Media>
          <Img fluid={data.Image1.childImageSharp.fluid} />
          <MediaOverlay>
            <div id="content">
              <h1>
                <span className="accent">The goal of early childhood education </span>
                should be to activate the child&apos;s own natural
                <span className="accent"> desire to learn</span>
              </h1>
              <h1>-- Dr. Maria Montessori</h1>
              <div id="buttons">
                <div id="left">
                  <Link to="/admissions/visit">
                    <Button secondary raised>
                      Visit Us
                    </Button>
                  </Link>
                </div>
                <div id="right">
                  <Link to="/admissions/apply">
                    <Button secondary raised>
                      Apply Now
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </MediaOverlay>
        </Media>
      </Card>
    )}
  />
)

export default MainPanel;
