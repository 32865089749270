import React from "react";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import List from "react-md/lib/Lists/List";
import ListItem from "react-md/lib/Lists/ListItem";
import DialogContainer from "react-md/lib/Dialogs/DialogContainer";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import Checkbox from "react-md/lib/SelectionControls/Checkbox";
import "./Splash.scss";

const SPLASHID = "MMWEB001";

class Splash extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
    this.hide = this.hide.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.dontshow = this.dontshow.bind(this);
  };

  componentDidMount() {
    const dontshow = localStorage.getItem(SPLASHID) === 'true';
    if(!dontshow) {
      this.setState({ visible: true });
    }
  }

  hide() {
    this.setState({ visible: false });
  };

  handleKeyDown(e) {
    const key = e.which || e.keyCode;
    if (key === 13 || key === 32) {
      // also close on enter or space keys
      this.hide();
    }
  };

  dontshow(checked) {
    if(checked) {
      localStorage.setItem(SPLASHID, true);
      this.hide();
    }
  };

  render() {
    const { visible } = this.state;
    return (
      <DialogContainer
        id="mmsplash"
        initialFocus="mmsplash"
        aria-labelledby="mmsplash"
        visible={visible}
        onHide={this.hide}
        width={800}
        contentClassName="splash"
      >
        <div id="splashbg" />
        <Card onClick={this.hide} onKeyDown={this.handleKeyDown}>
          <CardTitle title="We're open for Admissions 2021-2022!" />
          <CardText>
            <p className="md-text-justify">
              Mindful Montessori  is committed to upholding protective measures to keep our
              school and clients safe. Till we dont open physically we are conducting
              online sessions for 18months to 6 years.
            </p>
            <p className="md-text-justify">
              Key points:
            </p>
            <List>
              <ListItem leftIcon={<FontIcon>star</FontIcon>} primaryText="Small numbers of students in each session" />
              <ListItem leftIcon={<FontIcon>star</FontIcon>} primaryText="Internationally Accredited Curriculum" />
              <ListItem leftIcon={<FontIcon>star</FontIcon>} primaryText="  Balanced screen time" />
              <ListItem leftIcon={<FontIcon>star</FontIcon>} primaryText="Extensive Engagement" />
              <ListItem leftIcon={<FontIcon>star</FontIcon>} primaryText="Professionally trained and certified teachers" />
            </List>
          </CardText>
        </Card>
        <Checkbox
          id="checkbox-read-material-design-spec"
          name="simple-checkboxes[]"
          label="Don't show this again"
          value="material-design"
          onChange={this.dontshow}
        />
      </DialogContainer>
    )
  }
}

export default Splash;
