/* eslint-disable no-console */

import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Layout from '../layout';
// import MainSlider from '../components/MainSlider';
import MainPanel from '../components/MainPanel';
// import GoogleReviews from '../components/GoogleReviews';
import MainAbout from '../components/MainAbout';
import VideoTour from '../components/VideoTour';
import Testimonials from '../components/Testimonials';
import Splash from '../components/Splash';

class IndexPage extends React.Component {

  componentDidMount() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId      : '363971264163553',
        xfbml      : true,
        version    : 'v3.2'
      });

      window.FB.XFBML.parse();
    };

    // Workaround for anon function which ES6 frowns upon
    const myfunc = (d, s, id) => {
      if (d.getElementById(id)) {return;}
      const js = d.createElement(s);
      const fjs = d.getElementsByTagName(s)[0];
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    };
    myfunc(document, 'script', 'facebook-jssdk');

    // this.getReviews();
  }

  static getReviews() {
    (async () => {
      const rawResponse = await fetch('https://mybusiness.googleapis.com/v4/accounts/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: process.env.GATSBY_GOOGLE_BIZ_API_key})
      });
      const content = await rawResponse.json();
      console.log(content);
    })();
  }

  render () {
    return (
        <Layout>
          <Grid>
            <Cell
              desktopSize={8}
              tabletSize={6}
              tabletOffset={1}
              phoneSize={4}
            >
              <br />
              <MainPanel />
              <br />
              <Testimonials />
              <br />
              <MainAbout />
              <br />
              <VideoTour />
            </Cell>
            <div
              className="fb-page md-cell md-cell--4-desktop md-cell--6-tablet md-cell--1-tablet-offset md-cell--4-phone"
              data-href="https://www.facebook.com/mindfulmontessori"
              data-width="500"
              data-tabs="timeline"
              data-small-header="true"
              data-adapt-container-width="true"
              data-hide-cover="true"
              data-show-facepile="true"
            >
              <blockquote cite="https://www.facebook.com/mindfulmontessori" className="fb-xfbml-parse-ignore">
                <a href="https://www.facebook.com/mindfulmontessori">Mindful Montessori</a>
              </blockquote>
            </div>
          </Grid>
        </Layout>
    )
  }
}

export default IndexPage;
