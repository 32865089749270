/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import Slider from "react-slick";
import Card from "react-md/lib/Cards/Card";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import FontIcon from "react-md/lib/FontIcons/FontIcon";

import "./Testimonials.scss";

// TODO: Change the slider to something dynamic. Previews of blog posts e.g.

const settings =   {
  dots: true,
  arrows: true,
  adaptiveHeight: true,
  className: "testimonialsslider",
  dotsClass: "testimonialsdots",
  fade: true,
  autoplay: true,
  autoplaySpeed: 10000,
  infinite: true,
  speed: 1000,
  pauseOnHover: true,
  pauseOnDotsHover: true,
  pauseOnFocus: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="arrow nextArrow"
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
    >
      <FontIcon primary iconClassName="fa fa-chevron-right" />
    </div>
  );
}

function PrevArrow(props) {
  const {  onClick } = props;
  return (
    <div
      className="arrow prevArrow"
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
    >
      <FontIcon primary iconClassName="fa fa-chevron-left" />
    </div>
  );
}

const MainSlider = () => (

  <Card raised="true">
    <Grid>
      <Cell
        desktopSize={3}
        tabletSize={3}
        phoneSize={12}
      >
        <FontIcon error forceSize={48} forceFontSize>favorite</FontIcon>
        <p className="md-display-1">What Our Clients Are Saying...</p>
      </Cell>
      <Cell
        desktopSize={9}
        tabletSize={9}
        phoneSize={12}
      >
        <Slider {...settings}>
          <div>
            <p className="md-title">Deepti Ahlawat</p>
            <p className="md-align-center">
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
            </p>
            <p className="md-text-justify">
              <FontIcon iconClassName="fa fa-quote-left" />
              &nbsp;
              <font className="md-body-2 md-font-bold">
                I am in peace and happiness at my
                work because of Mindful Montessori.
              </font>
              &nbsp;
              I am truly filled with joy and content, seeing the way my son is
              growing up in all the avenues of life like Hygiene, Being Independent,
              discipline, healthy, social responsibility, maturity in thinking etc.
              They surpassed my expectation in handling kids by paying individual attention.
              They limit the students headcount to make sure no kids loose the attention,
              way they are being taught and keeping them active and positive all day.
              Above all my kid wakes up every morning and say I want to go school which
              makes me feel I am leaving him in safe hands and confident as a
              mother that I am giving him the best
              &nbsp;
              <FontIcon iconClassName="fa fa-quote-right" />
            </p>
          </div>
          <div>
            <p className="md-title">Dr Aditi</p>
            <p className="md-align-center">
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
            </p>
            <p className="md-text-justify">
              <FontIcon iconClassName="fa fa-quote-left" />
              &nbsp;
              <font className="md-body-2 md-font-bold">
                It’s been an unbelievable and wonderful journey with Mindful Montessori.
              </font>
              &nbsp;
              I met Lakhbir at a time when I was struggling with child care as
              my live in  maid hurt my 18 month old and just refused to cooperate
              with my parents who were there to supervise.
              <br />
              <br />
              My husband wasn’t very pleased with the idea of sending an 18 mth
              old to school. Today my husband attends the online classes with my
              son and really enjoys these sessions and feels that they are really
              helping him grow into a well rounded individual. I personally have
              seen the transition first hand in my son and appreciate and applaud
              the efforts of team Mindful Montessori from the bottom of my heart.
              <br />
              <br />
              Yes there were one or two occasions when I felt something’s
              needed to be better handled. Feedback given to Lakhbir was taken
              positively and promptly addressed. These are the moments that build
              your relationship.
              <br />
              <br />
              I enjoy spending more time with my son and
              doing his homework and assignments together I will always be grateful
              to mindful Montessori for the rock solid foundation that they are laying
              <br />
              <br />
              Thank you!
              &nbsp;
              <FontIcon iconClassName="fa fa-quote-right" />
            </p>
          </div>
          <div>
            <p className="md-title">Swathi Addanki</p>
            <p className="md-align-center">
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
            </p>
            <p className="md-text-justify">
              <FontIcon iconClassName="fa fa-quote-left" />
              &nbsp;
              Every child needs are unique , it becomes quite challenging as a
              parent when you want to get back to work at the same time & don&apos;t
              want to compromise on their needs . I was fortunate to have been
              introduced to Lakhbir & her team . My child today has an environment
              which meets his emotional needs & hygiene which are extremely important.
              He has developed good social skills, hands on learning & independence.
              I would highly recommend every parent to tour their facility to have
              an experience.
              &nbsp;
              <font className="md-body-2 md-font-bold">
                Lakhbir & her teaching staff can handle babies as
                small as a 4 month old!
              </font>
              &nbsp;
              Including my child it is so endearing to
              have seen babies to toddlers grown up to confident school going children!
              &nbsp;
              <FontIcon iconClassName="fa fa-quote-right" />
            </p>
          </div>
          <div>
            <p className="md-title">Mounica Mamatha</p>
            <p className="md-align-center">
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
            </p>
            <p className="md-text-justify">
              <FontIcon iconClassName="fa fa-quote-left" />
              &nbsp;
              Unlike others my journey with MindfullMontessari teachers started
              3.5 years ago ! And my baby today is 3.5 years! 😊 Yes you got it
              right as every working mother I was worried about 6 months later
              where will my baby will land in !Will that place be a safe place?
              Will I be able to manage work Nd life with out getting tensed about the kid?
              There were multiple questions and awaited answers making a swing in the head!
              While the time had approached for me to finally drop my kid in the
              montessari I still remember my question to the teacher will the kid
              will be fed on time? will there be a proper care taken?
              How frequent we can visit? Teacher understanding my plight said don&apos;t
              worry he is in safe hands with a smile 😊 👍🏻It was difficult for
              me as new mom to trust but yes to my surprise today I am still a
              working mom and my kid goes to same teachers but yes the place has
              slightly changed but the best part is as promised by Lakbir the
              standard has been maintained and has improved over the years!!
              &nbsp;
              <font className="md-body-2 md-font-bold">
                Thanks to all the teachers (second mother&apos;s) to my kid
              </font>
              &nbsp;
              and would definitely will have their impact in his growing from a boy to a
              successful person in his life🙏🏻And special thanks in supporting
              my career as without your support in taking care and shaping my
              kid to where he is currently I might have not reached to this
              juncture of my career ! Your help would always be cherished
              😊😊Special thanks for making me understand children’s
              perspective of doing things 👍🏻👍🏻😊
              &nbsp;
              <FontIcon iconClassName="fa fa-quote-right" />
            </p>
          </div>
          <div>
            <p className="md-title"> Sandeep Samuel</p>
            <p className="md-align-center">
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
            </p>
            <p className="md-text-justify">
              <FontIcon iconClassName="fa fa-quote-left" />
              &nbsp;
              Our twins enjoy Mindful Montessori and here is our detailed review.
              <br />
              <br />
              <b>Disclaimer</b>
              - It is important to note that the name
              &quot;Montessori&quot; is not copyrighted or trademarked in any way. So anyone
              can open a school and call it Montessori and any school can claim
              that it is offering an authentic Montessori program. The vast
              majority of schools in Hyderabad call themselves &quot;Montessori&quot;
              and do offer parts of the Montessori program, but once you research
              you will understand schools which follow the complete Montessori
              program and which do not - most of them do not follow the complete
              program. As a parent, you need to be educated, informed, and
              convinced about the Montessori program and make an informed choice for you and your child.
              <br />
              <br />
              At Mindful Montessori, the Administrator and Director Lakhbir
              encouraged us to observe the school before enrolling the twins.
              Since we already knew about the great work she did at CA Montessori,
              the conversation and observation was an excellent way to assess
              if the school environment suits the developmental needs of our
              kids - and yes it does.
              <br />
              <br />
              Here are our reasons why we chose Mindful Montessori:
            </p>
            <ul>
              <li>
                The physical environment is clean, hygienic, and beautiful.
              </li>
              <li>
                The school has a general feeling of calmness and peace.
              </li>
              <li>
                Kids are relaxed and happy.
              </li>
              <li>
                When you observe the kids you understand that they have a sense of purpose.
              </li>
              <li>
                The kids are kind and courteous with each other and the teachers.
              </li>
              <li>
                The teachers are always courteous with the kids and parents and
                always have a smile. They are much more attentive and
                aware of the kids and surroundings.
              </li>
            </ul>
            <p className="md-text-justify">
              The top reasons though are:
            </p>
            <ul>
              <li>
                We&apos;ve reviewed some top schools in Madhapur, Gachibowli,
                HiTech City and Mindful Montessori is way more genuinely clean.
              </li>
              <li>
                The team of teachers are young but still show a level of
                training and experience. My kids teachers Habiba and her
                assistant Abdela are always mindful and engaged. And we see
                this level of engagement with everyone of the teachers and staff.
                They are all pleasant to interact irrespective of the
                day or time. Lakbir has built a very good team.
              </li>
              <li>
                And we trust Lakbir as she is a trained AMI teacher and
                she runs the whole school professionally.
              </li>
            </ul>
            <p className="md-text-justify">
              Things to improve:
            </p>
            <ul>
              <li>
                Probably just the size of the outdoor play area. The school
                is still young so we understand that it&apos;s started small and is
                constrained by the location. Hopefully, the school expands
                to a nice and comfortable space for all the kids.
              </li>
            </ul>
            <p className="md-text-justify">
              So if you are thinking about Mindful Montessori, then I&apos;d first
              recommend to learn about the Montessori program, then physically
              check and review the schools around town, and then engage with Lakbir
              and the team - this will enable you to understand at what
              level Mindful Montessori is operating. All the best!
              &nbsp;
              <FontIcon iconClassName="fa fa-quote-right" />
            </p>
          </div>
          <div>
            <p className="md-title">Sama Satya</p>
            <p className="md-align-center">
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
            </p>
            <p className="md-text-justify">
              <FontIcon iconClassName="fa fa-quote-left" />
              &nbsp;
              I have been in search for a good play school for my kid , as i
              wanted him to be in a good hands when i&apos;m away to office.
              When i came to know about montessori school and fascinated with
              the teaching methods they adopt, i have checked out all the
              montessori schools near by Hitec city and Gachibowli but felt all
              these are commercial tactics and lastly
              &nbsp;
              <font className="md-body-2 md-font-bold">
                i gave a try to Mindful montessori and was really satisfied
              </font>
              &nbsp;
              with the way they handle kids,
              analyze them and make them comfortable. I&apos;m very much happy to
              have joined my kid in Mindful Montessori. Thank you Lakhbir and Team
              &nbsp;
              <FontIcon iconClassName="fa fa-quote-right" />
            </p>
          </div>
          <div>
            <p className="md-title">Khushboo Taneja</p>
            <p className="md-align-center">
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
            </p>
            <p className="md-text-justify">
              <FontIcon iconClassName="fa fa-quote-left" />
              &nbsp;
              I was in search of a good day care when my baby was 11 months old.
              I went out and looked only the best daycares mostly people talk about.
              That day I came back home disappointed with a heavy heart.
              I felt terribly helpless. I didn&apos;t like their standards, i hated
              the way they handled the babies, their environment wasn&apos;t safe at
              all for the smaller kids. Somewhere i thought of quitting up my job.
              My friends at my office told me about Mindful Montessori and
              about Lakhbir. I went to to the school and met Lakhbir. She
              explained me in detail about the montessorian learning program,
              their process and methods.
              &nbsp;
              <font className="md-body-2 md-font-bold">
                And much to my relief i saw a place
                where i can leave my baby when i am away for work
              </font>
              &nbsp;
              Lakhbir was
              able to put me back to confidence that i can still go to work as i
              am leaving my kid with a very trained and well educated staff.
              My baby is learning and growing in that environment and i go to
              work with confidence as i know i am leaving him with people
              who love him and help him with everything he needs. Thanks
              Lakhbir for maintaining such good standards and a safe
              learning environment for my baby.
              &nbsp;
              <FontIcon iconClassName="fa fa-quote-right" />
            </p>
          </div>
          <div>
            <p className="md-title">Deepashree</p>
            <p className="md-align-center">
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
            </p>
            <p className="md-text-justify">
              <FontIcon iconClassName="fa fa-quote-left" />
              &nbsp;
              It is very difficult for a working mom to leave her kid in a play
              school and go to work. But, sadly working moms have no choice.
              At the same time, we want a play school where our kids learn to be
              independent as well as be happy too. Especially for working moms,
              we wish a play school that becomes an extended family, who loves
              our kids as well as build a strong foundation for them.
              <br />
              <br />
              Mindful Montessori is a second home for my daughter. It has
              become an extended family where my daughter finds people who love
              as well as build a strong foundation for her. My daughter is
              learning and growing in a happy and healthy environment.
              <br />
              <br />
              I thank Lakhbir and team for creating a wonderful environment for
              the little ones. They are growing in a caring and creative environment.
              Teachers at Mindful Montessori are passionate professionals.
              &nbsp;
              <font className="md-body-2 md-font-bold">
                At Mindful Montessori, children go to the center with a happy face and leave with a happy face.
              </font>
              &nbsp;
              I recommend Working moms to join their kids in Mindful Montessori and work happily.
              Wish you the best to continue this good work.
              &nbsp;
              <FontIcon iconClassName="fa fa-quote-right" />
            </p>
          </div>
          <div>
            <p className="md-title">Dr Sharada Golconda</p>
            <p className="md-align-center">
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
              <FontIcon primary>star</FontIcon>
            </p>
            <p className="md-text-justify">
              <FontIcon iconClassName="fa fa-quote-left" />
              &nbsp;
              As a Medical professional and a new mother I was apprehensive about
              balancing my work-life n maternal care, not wanting to leave my baby
              in the hands of nanny alone for longer periods made me research for
              a better option. As a boon, my close friend, Aditi suggested me
              MINDFUL MONTESSORI, a next to perfect place for me to leave my
              bundle of joy without any hesitation. Daring many admonitions
              from near and dear ones I took a decision to leave my little one there.
              Today i am more than happy for doing so after seeing her progress
              in handling herself and becoming independent in all her regular chores.
              <br />
              <br />
              The foremost reason for me to be impressive about the place is its
              &nbsp;
              <font className="md-body-2 md-font-bold">
                policies like making the place a plastic free zone and digital
                screen free zone
              </font>
              &nbsp;
              for any newborn. Moreover, the sight of hearty
              welcome by Ms. Rumana and Ms.zara, immediately lights up my cutie
              pie&apos;s face speaks volumes about how much care they render to her...
              their boundless love and patience has taught my 10 month old baby
              to handle a ceramic glass properly is the best part of the umpteen
              skills they impart to the little munchkins.
              <br />
              <br />
              Since i fear that i can go on and on in mentioning the virtues or
              Mindful Montessori.. I want to conclude by strongly recmmending
              to any and every one who&apos;s in need of a second home for their
              precious bundles of love ...that THIS is the PLACE to put them,
              It doesn&apos;t occur to me to think of any flaws even for a moment
              about this place. LAKBIR, to be commended for making this place
              impeccable for our little ones. From the core of my heart I
              Thank you and wish you all as a team to prosper and progress by
              making our junios feel at home.
              &nbsp;
              <FontIcon iconClassName="fa fa-quote-right" />
            </p>
          </div>
        </Slider>
      </Cell>
    </Grid>
  </Card>
)

export default MainSlider;
