import React from "react";
import { Link } from 'gatsby';
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import List from "react-md/lib/Lists/List";
import ListItem from "react-md/lib/Lists/ListItem";
import Avatar from "react-md/lib/Avatars/Avatar";
import FontIcon from "react-md/lib/FontIcons/FontIcon";

import "./MainAbout.scss";

const MainAbout = () => (
  <Card>
    <CardTitle title="Mindful Montessori Early Learning Center" />
    <CardText className="md-text-justify">
      We provide an intimate and authentic Montessori program run by AMI certified teachers
      with a holistic approach focusing on the health and wellbeing of the child.
      Children make creative choices in learning while the teachers offer age-appropriate
      activities to guide the process.  Programs available for
      <Link to="/programs/infant"> infants</Link>
      &nbsp;(3-18 months),
      <Link to="/programs/toddler"> toddlers</Link>
      &nbsp;(1.5-3 years)
      <Link to="/programs/primary"> and primary</Link>
      &nbsp;(3-6 years).
      <br />
      <List className="md-text-justify">
        <ListItem
          leftAvatar={<Avatar suffix="blue" icon={<FontIcon>face</FontIcon>} />}
          primaryText="Trained professionals"
          secondaryText='Children are handled by trained Montessori professionals only. Untrained helpers are not used to take care of children - whether for changing diapers or cleaning messes'
          threeLines
        />
        <ListItem
          leftAvatar={<Avatar suffix="blue" icon={<FontIcon>child_care</FontIcon>} />}
          primaryText="Foster creativity"
          secondaryText='Unlike prevalent rigid and one-size-fits-all education systems, the Montessori method does not believe in fixed lesson plans. Children guide the process themselves by selecting activities from a wide array carefully crafted for each age group that focus on fostering fundamental skills appropriate for their age'
          threeLines
        />
        <ListItem
          leftAvatar={<Avatar suffix="blue" icon={<FontIcon>wc</FontIcon>} />}
          primaryText="Working parents"
          secondaryText='Specifically cater to working professionals with extended timings (9AM-6PM). No extra charges (e.g. late hour fees) for fundamental need'
          threeLines
        />
        <ListItem
          leftAvatar={<Avatar suffix="blue" icon={<FontIcon>security</FontIcon>} />}
          primaryText="Security"
          secondaryText='Fully monitored secure premises with utmost attention to child safety. Formal procedures ensure the children are ALWAYS secure'
          threeLines
        />
        <ListItem
          leftAvatar={<Avatar suffix="blue" icon={<FontIcon>list</FontIcon>} />}
          primaryText="Daily Tracker"
          secondaryText='Daily updates for parents sent by teachers – “Daily Tracker” (meal times, nap times, meal details, notable behavior…)'
          threeLines
        />
      </List>
      <p className="md-text-right md-caption"><Link to="/about/who">Learn more...</Link></p>
    </CardText>
  </Card>
)

export default MainAbout;
